import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import Header from 'library/molecules/Header'
import Layout from 'library/atoms/Layout'
import Link from 'next/link'
import Item, { ItemTextCta, ItemUl, ItemText } from 'library/molecules/Item'
import CoverImage from 'library/atoms/CoverImage'
import Head from 'next/head'
import useTranslation from 'next-translate/useTranslation'
import translateChargeableTitle from 'helpers/translateChargeableTitle'
import { TopLevelCard } from 'helpers/determineTopLevelChargeableData'
import { OutletPresenter, SchedulablePresenter } from 'shared/presenters'
import Icon from 'shared/library/atoms/Icon'
import { H5, H3 } from 'library/atoms/Typography'
import { ShowOnMarketplace } from 'shared/library/atoms/ConditionalMarketplace'
import { Text } from 'library/atoms/Typography'
import useStoreMarketplaceReferrals from 'state/useStoreMarketplaceReferrals'

interface HomeCard extends Omit<TopLevelCard, 'schedulable'> {
  schedulable: SchedulablePresenter
}

interface HomeProps {
  cards: HomeCard[]
  outlet: OutletPresenter
}

const Home = ({ cards, outlet }: HomeProps) => {
  const { t } = useTranslation('common')

  // Store marketplace source for session if utm set
  useStoreMarketplaceReferrals()

  return (
    <>
      <Head>
        <title>{`${outlet.name} - ${t('book-now')} | eola`}</title>
        {!outlet.feature_toggles?.marketplace_enabled && (
          <meta name="robots" content="noindex" />
        )}
      </Head>
      <StyledLayout>
        <Header />
        <main>
          <ShowOnMarketplace>
            <H3 textAlign="center">{outlet.name}</H3>
            <br />
            {/* <Text
              fontStyle="p2"
              color="secondary"
              dangerouslySetInnerHTML={{ __html: outlet.description() }}
            /> */}
          </ShowOnMarketplace>
          <ItemUl as="div" noGap={cards.length > 3}>
            {cards.length <= 3
              ? cards?.map(card => {
                  return (
                    <Fragment key={card.title}>
                      <Link href={card.href} passHref legacyBehavior>
                        <Item
                          variant="card"
                          title={translateChargeableTitle(card.title, t)}
                        >
                          <CoverImage src={card.cardImage} overlay />
                          <ItemTextCta>
                            <H5>{translateChargeableTitle(card.title, t)}</H5>
                            <Icon name="arrow-right" size={24} />
                          </ItemTextCta>
                        </Item>
                      </Link>
                    </Fragment>
                  )
                })
              : cards?.map(card => {
                  return (
                    <Fragment key={card.title}>
                      <Link href={card.href} passHref legacyBehavior>
                        <Item
                          variant="slimCard"
                          title={translateChargeableTitle(card.title, t)}
                        >
                          <CoverImage src={card.cardImage} overlay />
                          <ItemTextCta>
                            <H5>{translateChargeableTitle(card.title, t)}</H5>
                          </ItemTextCta>
                          <Icon name="card-arrow" size={24} />
                        </Item>
                      </Link>
                    </Fragment>
                  )
                })}
          </ItemUl>
          <ShowOnMarketplace>
            <ItemUl>
              {outlet.description() && (
                <Item>
                  <ItemText>
                    <H5>{t('common:about-outlet', { name: outlet.name })}</H5>
                    <Text
                      as="div"
                      color="secondary"
                      dangerouslySetInnerHTML={{
                        __html: outlet.description()
                      }}
                    />
                  </ItemText>
                </Item>
              )}
              <Item>
                <ItemText>
                  <H5>{t('common:about-outlet', { name: 'eola' })}</H5>
                  <Text color="secondary">{t('common:about-eola-body')}</Text>
                </ItemText>
              </Item>
            </ItemUl>
          </ShowOnMarketplace>
        </main>
      </StyledLayout>
    </>
  )
}

export default Home

const StyledLayout = styled(Layout)(({ theme }) => ({
  background: theme.colors.white
}))
