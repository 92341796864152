
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import { gql } from '@apollo/client';
import { client } from 'helpers/apollo';
import Home from 'library/organisms/Home';
import { SchedulablePresenter } from 'shared/presenters/SchedulablePresenter';
import { GetStaticProps } from 'next';
import determineTopLevelChargeableData, { TopLevelCard, WidgetWithAliases } from 'helpers/determineTopLevelChargeableData';
import { OutletPresenter } from 'shared/presenters/OutletPresenter';
import determineRedirectForHomePage from 'helpers/determineRedirectForHomePage';
interface WidgetProps {
    outlet: OutletPresenter;
    cards: TopLevelCard[];
}
const Widget = ({ outlet: outletJSON, cards: cardsJSON }: WidgetProps) => {
    const outlet = new OutletPresenter(outletJSON);
    const cards = cardsJSON?.map(card => ({
        ...card,
        schedulable: new SchedulablePresenter(card.schedulable)
    }));
    return <Home cards={cards} outlet={outlet}/>;
};
export default Widget;
export const getStaticPaths = async () => {
    return {
        paths: [],
        fallback: 'blocking'
    };
};
const getStaticProps: GetStaticProps = async ({ params, locale: localProp, defaultLocale: defaultLocaleProp }) => {
    const locale = localProp as string;
    const defaultLocale = defaultLocaleProp as string;
    const widgetSlug = params?.widgetSlug as string;
    const revalidate = 1;
    const widgetData: WidgetWithAliases = await fetchWidgetData(widgetSlug, locale);
    const cards = determineTopLevelChargeableData({
        widgetSlug,
        widgetData,
        locale,
        defaultLocale
    });
    if (!widgetData || !cards)
        return {
            notFound: true,
            revalidate
        };
    const redirectURL = determineRedirectForHomePage(cards, locale);
    if (redirectURL)
        return {
            redirect: {
                destination: redirectURL,
                permanent: false
            },
            revalidate
        };
    return {
        props: {
            outlet: widgetData?.outlet,
            cards
        },
        revalidate
    };
};
const fetchWidgetData = async (widgetSlug: string, locale: string) => {
    const { data } = await client.query({
        query: gql `
      query WidgetHomeFetchWidget($widgetSlug: String,  $locale: String!) {
        widgets(where: { slug: { _eq: $widgetSlug } }) {
          filter_by_category
          hide_calendar
          outlet_id
          id
          name
          primary
          outlet {
            id
            slug
            default_currency
            link_to_site
            name
            credit_vouchers_purchasable
            ${SCHEDULABLE}
            activity_key_image
            accommodation_key_image
            rental_key_image
            voucher_key_image
            credit_voucher_image
            membership_key_image
            translations(
              where: {
                language: { _in: [$locale, "en"] }
                field: { _in: ["description"] }
                model_type: { _eq: "Outlet" }
              }
            ) {
              value_scrubbed
              field
              language
            }
            categories(order_by: {ordering_weight: desc}) {
              id
              image
              translations(
                where: {
                  language: { _in: [$locale, "en"] }
                  field: { _in: ["name"] }
                  model_type: { _eq: "Category" }
                }
              ) {
                value_scrubbed
                field
                language
              }
              ${SCHEDULABLE}
            }
            feature_toggles {
              credit_vouchers_enabled
              class_passes_enabled
              rentals_enabled
              memberships_enabled
              marketplace_enabled
            }
            membership_products {
              id
            }
            activities_aggregate: schedulables_aggregate(
              where: {
                materialized_bookability_by_days: {
                  hide_from_public: {_eq: false }
                  schedulable_type: {_eq: "Activity"}
                }
              }
            ) ${AGGREGATE_COUNT}
            uncategorized_activities_aggregate: schedulables_aggregate(
              where: {
                materialized_bookability_by_days: {
                  hide_from_public: { _eq: false }
                  schedulable_type: { _eq: "Activity" }
                }
                _not: { category: {} }
              }
            ) ${AGGREGATE_COUNT}
            rentals_aggregate: schedulables_aggregate(
              where: {
                materialized_bookability_by_days: {
                  hide_from_public: {_eq: false }
                  schedulable_type: {_eq: "Rental"}
                }
              }
            ) ${AGGREGATE_COUNT}
            uncategorized_rentals_aggregate: schedulables_aggregate(
              where: {
                materialized_bookability_by_days: {
                  hide_from_public: { _eq: false }
                  schedulable_type: { _eq: "Rental" }
                }
                _not: { category: {} }
              }
            ) ${AGGREGATE_COUNT}
            accommodation_aggregate: schedulables_aggregate(
              where: {
                materialized_bookability_by_days: {
                  hide_from_public: {_eq: false }
                  schedulable_type: {_eq: "Accommodation"}
                }
              }
            ) ${AGGREGATE_COUNT}
            uncategorized_accommodation_aggregate: schedulables_aggregate(
              where: {
                materialized_bookability_by_days: {
                  hide_from_public: { _eq: false }
                  schedulable_type: { _eq: "Accommodation" }
                }
                _not: { category: {} }
              }
            ) ${AGGREGATE_COUNT}
            class_passes_aggregate(
              where: {
                hide_from_public: { _eq: false }
                discarded_at: { _is_null: true }
              }
            ) ${AGGREGATE_COUNT}
          }
          categories_widgets(order_by: {category: {ordering_weight: desc}}) {
            category {
              id
            }
          }
        }
      }
    `,
        variables: { widgetSlug, locale }
    });
    return data?.widgets[0];
};
const AGGREGATE_COUNT = `{
  aggregate {
    count
  }
}`;
/**
 * SCHEDULABLE query is conditionally called in specific scenarios.
 * It is limited to returning only one activity
 * We use this activity's image for the top level chargeable card image.
 * */
const SCHEDULABLE = `
   schedulables(
    limit: 1
    where: {
      materialized_bookability_by_days: {
        hide_from_public: { _eq: false }
      }
    }
    # Use most popular schedulable for card image
    order_by: { popularity: asc }
  ) {
      id
      type
      category_id
      slug
      created_at
      image
      members_only
      translations(
        where: {
          language: { _in: [$locale, "en"] }
          field: { _eq: "name" }
          model_type: { _eq: "Schedulable" }
        }
      ) {
        language
        field
        value_scrubbed
      }
      default_image {
        id
        image
      }
      child_price_pence
      price_pence
  }
`;

    async function __Next_Translate__getStaticProps__192779a1dfe__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[widgetSlug]/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__192779a1dfe__ as getStaticProps }
  